<template>
  <div class="main-back">
    <div class="row topmainrow">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="topmainlayer card">
          <div class="main-user-title">
            {{userName}}님, 안녕하세요<br/>
            <span class="main-user-title-m">Smart Work를 시작하세요</span>
          </div>
          <div class="main-weather">
            <div class="weather-icon2" v-if="!wheatherInfo.temp"><q-img :src="wheatherInfo.loading" style="width:50px" /></div>
            <div class="weather-icon" v-if="wheatherInfo.temp">{{ wheatherInfo.temp }} <q-img :src="wheatherInfo.icon" style="width:50px" /></div>
            <div class="main-weather-location" v-if="wheatherInfo.location">{{ wheatherInfo.location }} </div>
          </div>
        </div>
        <div class="row topmainbtns">
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
            <div class="maincard card" @click="goPage('/sop/swp/safeWorkPermit')">
              <div class="card-body">
                <div class="main-card-body-left">
                  <h5 class="title">안전작업허가서</h5>
                  <p class="subs">일반/화기</p>
                </div>
                <div class="main-card-body-right animated fadeIn">
                  <i class="material-icons text-red-6">home_repair_service</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
            <div class="maincard card" @click="goPage('/sai/patrol/patrol')">
              <div class="card-body">
                <div class="main-card-body-left">
                  <h5 class="title">순회점검</h5>
                  <p class="subs">Patrol</p>
                </div>
                <div class="main-card-body-right animated fadeIn">
                  <i class="material-icons text-green-6">change_circle</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
            <div class="maincard card" @click="goPage('/sop/ibm/impr')">
              <div class="card-body">
                <div class="main-card-body-left">
                  <h5 class="title">개선관리</h5>
                  <p class="subs">시정조치</p>
                </div>
                <div class="main-card-body-right animated fadeIn">
                  <i class="material-icons text-indigo-6">construction</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
            <div class="maincard card" @click="goPage('/sop/mim/inspection/equipmentDailyInspection')">
              <div class="card-body">
                <div class="main-card-body-left">
                  <h5 class="title">일상점검</h5>
                  <p class="subs">Daily</p>
                </div>
                <div class="main-card-body-right animated fadeIn">
                  <i class="material-icons text-deep-orange-6">engineering</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
            <div class="maincard card" @click="goPage('/sop/mim/smd/safetyCheck')">
              <div class="card-body">
                <div class="main-card-body-left">
                  <h5 class="title">공장 일일순찰</h5>
                  <p class="subs">Manager</p>
                </div>
                <div class="main-card-body-right animated fadeIn">
                  <i class="material-icons text-yellow-9">manage_accounts</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
            <div class="maincard card" @click="goPage('/sop/edu/result/educationResult')">
              <div class="card-body">
                <div class="main-card-body-left">
                  <h5 class="title">교육</h5>
                  <p class="subs">근로자 교육</p>
                </div>
                <div class="main-card-body-right animated fadeIn">
                  <i class="material-icons text-light-blue-6">school</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
            <div class="maincard card" @click="goPage('/sop/iim/near/nearAccident')">
              <div class="card-body">
                <div class="main-card-body-left">
                  <h5 class="title">아차사고</h5>
                  <p class="subs">사고예방</p>
                </div>
                <div class="main-card-body-right animated fadeIn">
                  <i class="material-icons text-deep-purple-6">personal_injury</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
            <div class="maincard card" @click="goPage('/sop/cto/cto')">
              <div class="card-body">
                <div class="main-card-body-left">
                  <h5 class="title">행동관찰</h5>
                  <p class="subs">Safety Check</p>
                </div>
                <div class="main-card-body-right animated fadeIn">
                  <i class="material-icons text-light-green-8">change_circle</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 topcarddiv">
            <div class="card noti-cardcontents">
              <div class="card-header" style="border-radius: 0.4375rem 0.4375rem 0 0;">
                공지사항
              </div>
              <div class="card-body no-padding-card">
                <q-table
                  grid
                  title=""
                  :data="data.noticeList"
                  hide-header
                  hide-bottom
                  :rows-per-page-options="[0]"
                  virtual-scroll
                >
                  <template v-slot:item="props">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                      <q-card class="mobileTableCardLayer-main" @click="getDetailPage('NOTICE',props.row)">
                        <q-card-section class="grid-card-etc">
                          <div :class="['text-grid-etc', getColorStatus3(props.row.noticeClassCd)]">
                            {{ props.row.noticeClassName }}
                          </div>
                          <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.noticeTitle }}</div>
                          <div class="text-grid-etc-right">
                            {{ props.row.regDtStr }}
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>
                  </template>
                </q-table>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="data.noticeList.length === 0">
                  <q-card class="mobileTableCardLayer-main">
                    <q-card-section horizontal class="text-center">
                      <q-card-section class="q-pt-xs">
                        <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs">등록된 공지사항이 없습니다.</div>
                      </q-card-section>
                    </q-card-section>
                  </q-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import mobileConfig from '@/js/mobileConfig';
export default {
  name: 'main-dashboard',
  props: {
  },
  data() {
    return {
      userName: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        noticeList: [],
      },
      listUrl: '',
      startDate: '',
      endDate: '',
      wheatherInfo: {
        icon: '',
        location: '',
        temp: '',
        loading: require('@/assets/images/weather/loading.png'),
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.userName = this.$store.getters.user.userName;
      this.GetGeo();
      this.listUrl = mobileConfig.main.list.url;

      this.startDate = this.$comm.getCalculatedDate(
        this.$comm.getToday(),
        '-6m',
        'YYYY-MM-DD',
        'YYYY-MM-DD',
      );
      this.endDate = this.$comm.getCalculatedDate(
        this.$comm.getToday(),
        '6m',
        'YYYY-MM-DD',
        'YYYY-MM-DD',
      )
      this.getList();
    },
    GetGeo() {
      if(!("geolocation" in navigator)) {
        return;
      } else {
        navigator.geolocation.getCurrentPosition(pos => {
          this.$http.url = '/api/openapi/weathers';
          this.$http.type = 'GET';
          this.$http.isLoading = false;
          this.$http.param = {
            lat: pos.coords.latitude,
            lon: pos.coords.longitude,
          };
          this.$http.request((_result) => {
            let location = _result.data.locs.split(' ');
            if (location.length > 0) {
              this.wheatherInfo.location = location[location.length-1]
            }
            this.wheatherInfo.icon = require('@/assets/images/weather/'+_result.data.weather[0].icon+'.png');
            this.wheatherInfo.temp = (parseFloat(_result.data.main.temp) -273.15).toFixed(1) + ' ℃';
          },);
        }, () => {
        })
      }

    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.isLoading = false;
      this.$http.param = {
        userId: this.$store.getters.user.userId,
        plantCd: this.$store.getters.user.plantCd,
        deptCd: this.$store.getters.user.deptCd,
        startDate: this.startDate,
        endDate: this.endDate,
        month: this.$comm.getThisMonth(),
      };
      this.$http.request((_result) => {
        if (_result.data.noticeList.length > 0) {
          this.data.noticeList = _result.data.noticeList;
        }
      },);
    },
    getDetailPage(type, key) {
      if (type === 'NOTICE') {
        this.popupOptions.title = '공지사항 상세'; // 공지사항 상세
        this.popupOptions.param = {
          sysNoticeId: key ? key.sysNoticeId : '',
        };
        this.popupOptions.target = () => import(`${'./noticePop.vue'}`);
      }
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus3(cd) {
      var cls = '';
      switch(cd) {
        case 'NCC0000001': // 일반
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'NCC0000002': // 긴급
          cls = 'txt-box-grid text-red-box';
          break;
      }
      return cls;
    },
  }
};
</script>
<style lang="scss">
.main-back {
  height: calc(100vh - 100px);
  background-color: #edf1f6;
}
.topmainlayer {
  box-shadow: 0 0 0 0;
  border-width: 0px !important;
  background-color: #3949ab;
  display: block;
  height: 120px;
  border-radius: 0 0 100% 100%/0 0 10vw 10vw;
}
.main-card-body-left {
  width: 100%;
  align-items: center!important;
  justify-content: center!important;
}
.main-card-body-right {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.main-user-title {
  color: #fff;
  padding: 6px 0 0 20px;
  float: left;
  font-size: 1.1em;
  width: 50%;
}
.weather-icon {
  margin-top: -7px;
}
.weather-icon2 {
  margin-top: 6px;
}
.main-weather {
  color: #fff;
  width: 50%;
  padding: 0px 20px 0 0;
  text-align: right;
  float: right;
}
.main-weather-location {
  margin-top: -7px;
  padding: 0 10px 0 0;
}
.main-user-title-m {
  font-size: 0.85em;
}
.maincard {
  margin-bottom: 1px;
  min-height: 100px;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.07);
  border-radius: 0.4375rem;
  box-shadow: 0 1rem 1rem -0.75rem rgba(105,96,215,.175);
}
.maincard .card-body {
  background: #fff;
  color:#807e7e;
  border-radius: 5px;
  padding: 15px !important;
  .fw-bold {
    font-weight: 600;
  }
  .material-icons {
    font-size: 3em;
    color:#807e7e;
    cursor: pointer;
  }
  .title {
    font-weight: 600;
    font-size: 1.2em;
  }
  .subs {
    font-size: 1em;
  }
}
.topmainbtns {
  padding-top: 10px;
  width: 95%;
  margin: 0 auto !important;
  margin-top: -60px !important;
  .topcarddiv {
    padding:5px !important;
  }
}
.noti-cardcontents {
  border-radius: 0.4375rem;
  margin-bottom: 60px;
}
</style>